<template>
  <div class="container" style="padding-bottom:1.5rem">
    <h2 class="reporttitle">{{title}}</h2>
    <div class="formbox">
      <h3 class="formtitle">学生本人{{title}}信息</h3>
      <div class="formitem">
        <label class="formitemtitle">接种针数：</label>
        <input class="formitemcon" type="text" placeholder="请选择已接种针数" v-model="doNumText" readonly @click="resultClick({who:'student'})">
        <img src="@/assets/images/gengduo.png" class="choice">
      </div>
      <div class="formitem">
        <label class="formitemtitle">接种时间：</label>
        <input class="formitemcon" type="text" placeholder="请选择接种时间" v-model="doTime" readonly @click="dateClick({who:'student'})">
        <img src="@/assets/images/gengduo.png" class="choice">
      </div>
      <div class="formitem fivewords">
        <label class="formitemtitle">未接种说明：</label>
        <input class="formitemcon" type="text" placeholder="若该学生未接种，请填写接种说明" v-model="note">
      </div>
    </div>
    <div class="formbox" v-for="(item,index) of parents" :key="index">
      <span class="delbtn" @click="del(index)"><img src="@/assets/images/del.png">删除</span>
      <h3 class="formtitle">家长{{title}}信息</h3>
      <div class="formitem">
        <label class="formitemtitle">家长姓名：</label>
        <input class="formitemcon" type="text" placeholder="请输入家长姓名" v-model="item.name">
      </div>
      <div class="formitem">
        <label class="formitemtitle">家长身份：</label>
        <input class="formitemcon" type="text" placeholder="请输入家长身份" v-model="item.relation">
      </div>
      <div class="formitem">
        <label class="formitemtitle">接种针数：</label>
        <input class="formitemcon" type="text" placeholder="请选择已接种针数" v-model="item.doNumText" @click="resultClick({who:'parent',index})" readonly>
        <img src="@/assets/images/gengduo.png" class="choice">
      </div>
      <div class="formitem">
        <label class="formitemtitle">接种时间：</label>
        <input class="formitemcon" type="text" placeholder="请选择接种时间" v-model="item.doTime" @click="dateClick({who:'parent',index})" readonly>
        <img src="@/assets/images/gengduo.png" class="choice">
      </div>
      <div class="formitem fivewords">
        <label class="formitemtitle">未接种说明：</label>
        <input class="formitemcon" type="text" placeholder="若该学生未接种，请填写接种说明" v-model="item.note">
      </div>
    </div>
    <div class="addbtn" @click="add"><img src="@/assets/images/add.png">添加其他人疫苗接种信息</div>
    <div class="submitbtn" @click="submit">提交</div>
    <van-popup v-model="dateShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择疫苗接种时间"
        :max-date="maxDate"
        @confirm="dateConfirm"
        @cancel="dateShow=false"
      />
    </van-popup>
    <van-popup v-model="resultShow" position="bottom">
      <van-picker
        show-toolbar
        :columns="resultColumns"
        :default-index="defaultIndex"
        @confirm="resultConfirm"
        @cancel="resultCancel"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stuId:"",
      stuName:"",
      title:"",
      doTime:"",
      doNum:"",
      doNumText:"",
      note:"",
      dateShow:false,
      currentDate: new Date(),
      maxDate: new Date(),
      parents:[
        {
          name:"",
          relation:"",
          doTime:"",
          doNum:"",
          doNumText:"",
          dateShow:false,
          note:"",
        }
      ],
      whoText:"student",
      whoIndex:0,
      resultShow:false,
      resultColumns: [{
        text:"已接种第一针",
        value:1,
      },{
        text:"已接种第二针",
        value:2,
      },{
        text:"已接种第三针",
        value:3,
      },{
        text:"未接种",
        value:0,
      }],
      defaultIndex:0,
    };
  },
  methods:{
    del(index){
      this.parents.splice(index,1)
    },
    submit(){
      if(this.doNum==""){
        this.$toast("请选择学生本人接种针数")
        return
      }
      if(this.doTime==""){
        this.$toast("请选择学生本人接种时间")
        return
      }
      if(this.doNum=="未接种"&&this.note.trim()==""){
        this.$toast("请填写学生本人未接种说明")
        return
      }
      var parentName = this.parents.some(function(item, index, array){
        return item.name.trim() == ""
      })
      if(parentName){
        this.$toast("请填写家长姓名")
        return
      }
      var parentRelation = this.parents.some(function(item, index, array){
        return item.relation.trim() == ""
      })
      if(parentRelation){
        this.$toast("请填写家长关系")
        return
      }
      var parentNum = this.parents.some(function(item, index, array){
        return item.doNum == ""
      })
      if(parentNum){
        this.$toast("请选择家长接种针数")
        return
      }
      var parentTime = this.parents.some(function(item, index, array){
        return item.doTime == ""
      })
      if(parentTime){
        this.$toast("请选择家长接种时间")
        return
      }
      var parentNote = this.parents.some(function(item, index, array){
        return item.doNum == "未接种" && item.note.trim()==""
      })
      if(parentNote){
        this.$toast("请填写家长未接种说明")
        return
      }
      var dataJson = {
        stuName:this.stuName,
        stuId:this.stuId,
        doTime:this.doTime,
        doNum:this.doNum,
        note:this.note,
        parents:this.parents,
      }
      this.axios.post("/prod-api/parents/report/ym",dataJson)
      .then(res=>{
        if(res.code==200){
          this.$toast.success("提交成功")
          this.$router.go(-1)
        }else{
          this.$toast(res.msg)
        }
      })
    },
    add(){
      this.parents.push({
        name:"",
        relation:"",
        doTime:"",
        doNum:"",
        dateShow:false,
        note:"",
      })
    },
    resultClick({who,index}){
      this.whoText = who
      this.whoIndex = index
      this.defaultIndex = 0
      if(who=="student"&&this.doNum){
        this.defaultIndex = this.resultColumns.indexOf(this.doNum)
      }else if(who=="parent"&&this.parents[index].doNum){
        this.defaultIndex = this.resultColumns.indexOf(this.parents[index].doNum)
      }
      this.resultShow = true
    },
    resultConfirm(value, index) {
      if(this.whoText == "student"){
        this.doNum = value.value
        this.doNumText = value.text
      }else{
        this.parents[this.whoIndex].doNum = value.value
        this.parents[this.whoIndex].doNumText = value.text
      }
      this.defaultIndex = index
      this.resultShow = false
    },
    resultCancel() {
    },
    dateClick({who,index}){
      this.whoText = who
      this.whoIndex = index
      this.dateShow = true
      this.currentDate=new Date()
      if(who=='student' && this.doTime){
        this.currentDate = new Date(this.doTime)
      }else if(who=='parent' && this.parents[index].doTime){
        this.currentDate = new Date(this.parents[index].doTime)
      }
    },
    dateConfirm(val){
      let year = val.getFullYear()//年
      let month = val.getMonth() + 1//月
      let day = val.getDate()//日
      // let hour = val.getHours()//时
      // let minute = val.getMinutes()//分
      if (month >= 1 && month <= 9) { month = `0${month}` }
      if (day >= 1 && day <= 9) { day = `0${day}` }
      // if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
      // if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
      // this.user_birthday = `${year}-${month}-${day} ${hour}:${minute}`
      if(this.whoText == "student"){
        this.doTime = `${year}-${month}-${day}`
      }else{
        this.parents[this.whoIndex].doTime = `${year}-${month}-${day}`
      }
      this.dateShow = false
    },
  },
  created(){
    this.stuId = this.$route.query.stuId
    this.stuName = this.$route.query.stuName
    this.title = this.$route.query.title
  }
}
</script>

<style src="../assets/css/schoolmeshing.css" scoped></style>
<style scoped>

</style>