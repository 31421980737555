<template>
  <div class="container">
    <div class="formbox">
      <div class="formitem">
        <label class="formitemtitle">学校班级：</label>
        <input class="formitemcon" type="text" placeholder="选择学生所在学校班级" v-model="fieldValue" readonly @click="show = true">
        <img src="@/assets/images/gengduo.png" class="choice">
      </div>
      <div class="formitem">
        <label class="formitemtitle">学生姓名：</label>
        <input class="formitemcon" type="text" v-model="studentName" placeholder="请输入姓名">
      </div>
      <span class="listbtn" @click="search">搜索</span>
    </div>
    <div v-if="searchList.length>0">
      <h2 class="reporttitle">搜索结果</h2>
      <div class="formbox">
        <van-radio-group v-model="radio">
          <div class="searchresultitem" @click="selectResults(item.id)" v-for="(item,index) of searchList" :key="index">
            <div class="resultinfo">
              <dl><dt>学生姓名：</dt><dd>{{item.name}}</dd></dl>
              <dl><dt>所在班级：</dt><dd>{{item.classes}}</dd></dl>
              <dl><dt>所在学校：</dt><dd>{{item.school}}</dd></dl>
            </div>
            <div class="resultradio">
              <van-radio :name="item.id+''" />
            </div>
          </div>
        </van-radio-group>
      </div>
      <div class="submitbtn" @click="confirm">确定</div>
    </div>
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择学校班级"
        :options="options"
        @close="show = false"
        @finish="onFinish"
        :field-names="fieldNames"
        active-color="#4e80e2"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data(){
    return{
      radio:"",
      searchList:[],
      show: false,
      fieldValue: '',
      cascaderValue: '',
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [],
      fieldNames: {
        text: 'deptName',
        value: 'deptId',
        children: 'classes',
      },
      fieldValue:"",
      classesId:"",
      studentName:"",
    }
  },
  methods:{
    selectResults(id){
      this.radio = id+""
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.deptName).join('/');
      this.classesId = selectedOptions[selectedOptions.length-1].deptId
    },
    getClasses(){
      this.axios.get("/prod-api/parents/school/classes")
      .then(res=>{
        this.options = res.data
      })
    },
    search(){
      if(this.fieldValue==""){
        this.$toast("请选择班级")
        return
      }
      if(this.studentName.trim()==""){
        this.$toast("请输入姓名")
        return
      }
      this.axios.get("/prod-api/parents/students/search",{params:{deptId:this.classesId,name:this.studentName}})
      .then(res=>{
        this.searchList = res.data
      })
    },
    confirm(){
      if(this.radio==""){
        this.$toast("请选择一个学生")
        return
      }
      this.$router.push({path:"/relation",query:{stuId:this.radio}})
    }
  },
  created(){
    this.getClasses()
  }
}
</script>

<style src="../assets/css/schoolmeshing.css" scoped></style>
<style scoped>

</style>