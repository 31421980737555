<template>
  <div class="container" style="padding-bottom:1.5rem">
    <h2 class="reporttitle">体温上报</h2>
    <div class="formbox">
      <h3 class="formtitle">学生本人体温上报</h3>
      <div class="formitem">
        <label class="formitemtitle">体温数值：</label>
        <!-- <input class="formitemcon" placeholder="请输入体温"> -->
        <van-field
          class="formitemcon"
          v-model="temperature"
          placeholder="请输入体温"
          input-align="right"
          type="number"
        />
        <span class="bluetext">℃</span>
      </div>
    </div>
    <div class="submitbtn" @click="submit">提交</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stuId:"",
      stuName:"",
      title:"",
      temperature:"",
    };
  },
  methods:{
    submit(){
      if(this.temperature.trim()==""){
        this.$toast("请输入体温")
        return
      }
      var dataJson = {
        stuName:this.stuName,
        stuId:this.stuId,
        temperature:this.temperature,
      }
      this.axios.post("/prod-api/parents/report/tw",dataJson)
      .then(res=>{
        if(res.code==200){
          this.$toast.success("提交成功")
          this.$router.go(-1)
        }else{
          this.$toast(res.msg)
        }
      })
    },
  },
  created(){
    this.stuId = this.$route.query.stuId
    this.stuName = this.$route.query.stuName
    this.title = this.$route.query.title
  }
}
</script>

<style src="../assets/css/schoolmeshing.css" scoped></style>
<style scoped>
.van-cell{
  padding: 0;
  line-height: 0.4rem;
  font-size: 0.3rem;
}

.formitemcon>>>input.van-field__control::-webkit-input-placeholder {
  color: #777;
}
</style>