<template>
  <div style="padding-top:50px">
    <div style="padding-bottom:30px">共{{nameList.length}}人</div>
    <div v-if="fzList.length==0">
      <van-cell-group>
        <van-field v-model="group" label="分几组" placeholder="请输入用户名" />
        <van-field v-model="person" label="一组几人" placeholder="请输入用户名" />
      </van-cell-group>
      <van-button type="primary" style="margin-top:50px" block @click="submit">提交</van-button>
    </div>
    <div v-else>
      <ul v-for="(item,index) of fzList" :key="index" class="group">
        <li>第{{index+1}}组</li>
        <li v-for="(name,i) of item" :key="i">{{name.awardTitle}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      group:"",
      person:"",
      fzList:[],
      nameList:[],
    }
  },
  methods:{
    submit(){
      this.axios.get("/prod-api/happy/all/fz",{params:{num:this.group,count:this.person}})
      .then(res=>{
        this.fzList = res
      })
    },
    getData(){
      this.axios.get("/prod-api/happy/all")
      .then(res=>{
        this.nameList = res
      })
    },
  },
  created(){
    this.getData()
  }
}
</script>

<style scoped>
.group{
  overflow: hidden;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
.group li{
  float: left;
  width: 100px;
  line-height: 30px;
  text-align: center;
}
</style>