<template>
  <div class="authorizebox">
    <h2>授权 校园通 获取以下信息为您服务：</h2>
    <p>获取您的公开信息（微信头像、微信昵称等）</p>
    <p>获取您的孩子的相关信息（包括但不限于学校、班级、姓名等）</p>
    <div class="agree"><van-checkbox v-model="checked" shape="square" icon-size="16px"></van-checkbox><label>我已认真阅读并同意<span class="bluetext">《用户服务协议》</span>及<span class="bluetext">《隐私政策》</span></label></div>
    <ul class="verticalbtn">
      <li @click="agree">允许</li>
      <li @click="refuse" class="graybg">拒绝</li>
    </ul>
  </div>
</template>

<script>
export default {
  data(){
    return{
      checked: true,
    }
  },
  methods:{
    refuse(){
      if(!this.checked) return
      setTimeout(function() {
        //这个可以关闭安卓系统的手机
        document.addEventListener(
            "WeixinJSBridgeReady",
            function() {
              WeixinJSBridge.call("closeWindow");
            },
          false
        );
          //这个可以关闭ios系统的手机
        WeixinJSBridge.call("closeWindow");
      },500)
    },
    agree(){
      if(!this.checked) return
      this.$router.push("/mine")
      // this.$router.push(sessionStorage.getItem("url"))
    }
  }
}
</script>

<style src="../assets/css/schoolmeshing.css" scoped></style>
<style scoped>

</style>