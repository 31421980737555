<template>
  <div>
    <van-cell-group>
      <van-field v-model="item.probability" :label="item.awardTitle" v-for="(item,index) of nameList" :key="index" placeholder="请输入用户名" @blur="probabilityChange(item)" />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  data(){
    return{
      nameList:[]
    }
  },
  methods:{
    getData(){
      this.axios.get("/prod-api/happy/all")
      .then(res=>{
        this.nameList = res
      })
    },
    probabilityChange(item){
      this.axios.post("/prod-api/happy/award",item)
    }
  },
  created(){
    this.getData()
  }
}
</script>

<style scoped>

</style>