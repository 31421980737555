<template>
  <div class="container">
    <div class="report"><img src="@/assets/images/report.png" @click="show=true"></div>
    <ul class="stulist" v-if="stuList.length>1">
      <li :class="{active:item.checked}" v-for="(item,index) of stuList" :key="index" @click="change(item)">{{item.name}}</li>
    </ul>
    <van-tabs color="#4e80e2" v-model="tabActive" @click="tabChange" :style="{'top':stuList.length>1?'1.02rem':'0'}">
      <van-tab v-for="(tab,index) of tabList" :name="tab.value" :title="tab.text" :key="index"></van-tab>
    </van-tabs>
    <div class="listbox">
      <div class="empty" v-if="isEmpty">暂无上报信息</div>
      <div class="listitem" v-for="(item,index) of reportList" :key="index" v-else>
        <!-- <dl class="infocell"><dt>上报时间：</dt><dd></dd></dl> -->
        <!-- <dl class="infocell"><dt>上报类型：</dt><dd class="bluetext">疫苗接种</dd></dl> -->
        <div v-if="tabActive=='ym'">
          <dl class="infocell"><dt>学生姓名：</dt><dd>{{item.stuName}}</dd></dl>
          <dl class="infocell"><dt>接种针数：</dt><dd class="bluetext">{{item.doNum==0?'未接种疫苗':`已接种第${item.doNum}针`}}</dd></dl>
          <dl class="infocell"><dt>接种时间：</dt><dd>{{item.doTime}}</dd></dl>
          <dl class="infocell" v-if="item.note"><dt>原因：</dt><dd>{{item.note}}</dd></dl>
        </div>
        <div v-if="tabActive=='hs'">
          <dl class="infocell"><dt>学生姓名：</dt><dd>{{item.stuName}}</dd></dl>
          <dl class="infocell"><dt>检测时间：</dt><dd>{{item.doTime}}</dd></dl>
          <dl class="infocell"><dt>检测结果：</dt><dd :class="[item.doResult=='阳性'?'redtext':'bluetext']">{{item.doResult}}</dd></dl>
        </div>
        <div v-if="tabActive=='tw'">
          <dl class="infocell"><dt>学生姓名：</dt><dd>{{item.stuName}}</dd></dl>
          <dl class="infocell"><dt>体温数值：</dt><dd :class="[item.temperature>37.2?'redtext':'bluetext']">{{item.temperature}}℃</dd></dl>
        </div>
        <div v-if="tabActive=='gl'">
          <dl class="infocell"><dt>学生姓名：</dt><dd>{{item.stuName}}</dd></dl>
          <dl class="infocell"><dt>上报时间：</dt><dd>{{item.subTime}}</dd></dl>
          <dl class="infocell"><dt>隔离周期：</dt><dd class="bluetext">{{item.startTime}}至{{item.endTime}}</dd></dl>
        </div>
        <div v-show="item.isOpen">
          <div v-if="tabActive=='ym'">
            <h3 class="listitemtitle">家长疫苗接种信息</h3>
            <div class="infocellbox" v-for="(parent,i) of item.parents" :key="i">
              <dl class="infocell"><dt>家长姓名：</dt><dd>{{parent.name}}</dd></dl>
              <dl class="infocell"><dt>家长身份：</dt><dd class="bluetext">{{parent.relation}}</dd></dl>
              <dl class="infocell"><dt>接种针数：</dt><dd class="bluetext">{{parent.doNum==0?'未接种疫苗':`已接种第${parent.doNum}针`}}</dd></dl>
              <dl class="infocell"><dt>接种时间：</dt><dd>{{parent.doTime}}</dd></dl>
              <dl class="infocell" v-if="parent.note"><dt>原因：</dt><dd>{{parent.note}}</dd></dl>
            </div>
          </div>
          <div v-if="tabActive=='hs'">
            <h3 class="listitemtitle">家长核酸检测信息</h3>
            <div class="infocellbox" v-for="(parent,i) of item.parents" :key="i">
              <dl class="infocell"><dt>家长姓名：</dt><dd>{{parent.name}}</dd></dl>
              <dl class="infocell"><dt>家长身份：</dt><dd class="bluetext">{{parent.relation}}</dd></dl>
              <dl class="infocell"><dt>检测时间：</dt><dd>{{parent.doTime}}</dd></dl>
              <dl class="infocell"><dt>检测结果：</dt><dd :class="[parent.doResult=='阳性'?'redtext':'bluetext']">{{parent.doResult}}</dd></dl>
            </div>
          </div>
        </div>
        <span class="arrow" :class="[item.isOpen?'collapse':'expand']" v-if="item.parents&&item.parents.length>0" @click="open(index)">{{item.isOpen?'收起':'展开'}}</span>
      </div>
    </div>
    <van-popup v-model="show" position="bottom">
      <van-picker
        title="标题"
        show-toolbar
        :columns="tabList"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import NameTab from "../components/NameTab.vue"
export default {
  data(){
    return{
      stuList:[],
      stuId:"",
      stuName:"",
      tabActive:"疫苗接种",
      tabList:[{
        text:"疫苗接种",
        value:"ym",
        type:"2",
      },{
        text:"核酸检测",
        value:"hs",
        type:"1",
      },{
        text:"体温上报",
        value:"tw",
        type:"3",
      },{
        text:"居家隔离",
        value:"gl",
        type:"4",
      },{
        text:"集中隔离",
        value:"gl",
        type:"5",
      },{
        text:"居家健康监控",
        value:"gl",
        type:"6",
      }],
      reportList:[],
      glType:"",
      isEmpty:false,
      show:false,
    }
  },
  components:{
    NameTab
  },
  methods:{
    onConfirm(value, index) {
      this.$router.push({path:`/report${value.value}`,query:{stuName:this.stuName,stuId:this.stuId,title:value.text}})
    },
    onCancel() {
      this.show = false
    },
    tabChange(name,title){
      if(title=="居家隔离") this.glType = "4"
      if(title=="集中隔离") this.glType = "5"
      if(title=="居家健康监控") this.glType = "6"
      this.getData(this.tabActive)
    },
    open(index){
      this.reportList[index].isOpen = this.reportList[index].isOpen?false:true
    },
    getData(reportType){
      var dataJson
      if(reportType!="gl"){
        dataJson={
          stuId:this.stuId
        }
      }else{
        dataJson={
          stuId:this.stuId,
          type:this.glType
        }
      }
      this.axios.get(`/prod-api/parents/report/${reportType}`,{params:dataJson})
      .then(res=>{
        this.reportList = res.data
        for(var item of this.reportList){
          this.$set(item,"isOpen",false)
        }
        if(this.reportList.length==0){
          this.isEmpty = true
        }else{
          this.isEmpty = false
        }
      })
    },
    change(key){
      for(var item of this.stuList){
        item.checked = false
      }
      key.checked = true
      this.stuId = key.stuId
      this.stuName = key.name
      this.getData(this.tabActive)
    },
    getStu(){
      this.axios.get("/prod-api/parents/students/simplify")
      .then(res=>{
        this.stuList = res.data
        this.stuId = this.stuList[0].stuId
        this.stuName = this.stuList[0].name
        for(var item of this.stuList){
          this.$set(item,"checked",false)
        }
        this.stuList[0].checked = true
        this.getData("ym")
      })
    },
  },
  created(){
    this.getStu()
  }
}
</script>

<style src="../assets/css/schoolmeshing.css" scoped></style>
<style scoped>
.listitem:first-child{
  margin-top: 0.24rem;
}
.van-tabs{
  position: sticky;
  top: 1.02rem;
}
</style>