import Vue from 'vue'
import VueRouter from 'vue-router'
import Notice from '../views/Notice.vue'
import ServicePayment from '../views/ServicePayment.vue'
import ToPay from '../views/ToPay.vue'
import ServiceReport from '../views/ServiceReport.vue'
import ReportHs from '../views/ReportHs.vue'
import ReportYm from '../views/ReportYm.vue'
import ReportTw from '../views/ReportTw.vue'
import ReportGl from '../views/ReportGl.vue'
import Mine from '../views/Mine.vue'
import AddStudents from '../views/AddStudents.vue'
import Relation from '../views/Relation.vue'
import Authorize from '../views/Authorize.vue'
import Login from '../views/Login.vue'
import LuckdrawSave from '../views/LuckdrawSave.vue'
import LuckdrawWh from '../views/LuckdrawWh.vue'
import LuckdrawFz from '../views/LuckdrawFz.vue'

Vue.use(VueRouter)

const routes = [
  {//通知
    path: '/notice',
    name: 'Notice',
    component: Notice
  },
  {//服务缴费
    path: '/servicepayment',
    name: 'ServicePayment',
    component: ServicePayment
  },
  {//去缴费
    path: '/topay',
    name: 'ToPay',
    component: ToPay
  },
  {//服务上报
    path: '/servicereport',
    name: 'ServiceReport',
    component: ServiceReport
  },
  {//上报核酸
    path: '/reporths',
    name: 'ReportHs',
    component: ReportHs
  },
  {//上报疫苗
    path: '/reportym',
    name: 'ReportYm',
    component: ReportYm
  },
  {//上报体温
    path: '/reporttw',
    name: 'ReportTw',
    component: ReportTw
  },
  {//上报隔离
    path: '/reportgl',
    name: 'ReportGl',
    component: ReportGl
  },
  {//我的
    path: '/mine',
    name: 'Mine',
    component: Mine
  },
  {//添加学生
    path: '/addstudents',
    name: 'AddStudents',
    component: AddStudents
  },
  {//绑定家长
    path: '/relation',
    name: 'Relation',
    component: Relation
  },
  {//授权
    path: '/authorize',
    name: 'Authorize',
    component: Authorize
  },
  {//登录
    path: '/login',
    name: 'Login',
    component: Login
  },
  {//抽奖用户保存
    path: '/luckdrawsave',
    name: 'LuckdrawSave',
    component: LuckdrawSave
  },
  {//抽奖维护
    path: '/luckdrawwh',
    name: 'LuckdrawWh',
    component: LuckdrawWh
  },
  {//抽奖分组
    path: '/Luckdrawfz',
    name: 'LuckdrawFz',
    component: LuckdrawFz
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
