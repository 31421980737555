<template>
  <div style="padding-top:50px">
    <van-cell-group>
      <van-field v-model="value" label="姓名" placeholder="请输入用户名" />
    </van-cell-group>
    <van-button type="primary" style="margin-top:50px" block @click="save">保存</van-button>
  </div>
</template>

<script>
export default {
  data(){
    return{
      value:"",
    }
  },
  methods:{
    save(){
      this.axios.post("/prod-api/happy/award/add",{awardTitle:this.value})
      .then(res=>{
        
      })
    }
  }
}
</script>

<style scoped>

</style>