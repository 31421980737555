<template>
  <div class="container" style="padding-bottom:1.5rem">
    <h2 class="reporttitle">{{title}}</h2>
    <div class="formbox">
      <h3 class="formtitle">学生本人隔离信息上报</h3>
      <div class="formitem">
        <label class="formitemtitle">开始日期：</label>
        <input class="formitemcon" type="text" placeholder="请选择开始日期" v-model="startTime" readonly @click="dateClick('start')">
        <img src="@/assets/images/gengduo.png" class="choice">
      </div>
      <div class="formitem">
        <label class="formitemtitle">解封日期：</label>
        <input class="formitemcon" type="text" placeholder="请选择解封日期" v-model="endTime" readonly @click="dateClick('end')">
        <img src="@/assets/images/gengduo.png" class="choice">
      </div>
    </div>
    <div class="submitbtn" @click="submit">提交</div>
    <van-popup v-model="dateShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择隔离时间"
        @confirm="dateConfirm"
        @cancel="dateShow=false"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stuId:"",
      stuName:"",
      title:"",
      startTime:"",
      endTime:"",
      dateShow:false,
      currentDate: new Date(),
      currentType:"",
    };
  },
  methods:{
    submit(){
      if(this.startTime==""){
        this.$toast("请选择开始日期")
        return
      }
      if(this.endTime==""){
        this.$toast("请选择解封日期")
        return
      }
      var dataJson = {
        stuName:this.stuName,
        stuId:this.stuId,
        startTime:this.startTime,
        endTime:this.endTime,
      }
      if(this.title=='居家隔离') this.$set(dataJson,'type',"4")
      if(this.title=='集中隔离') this.$set(dataJson,'type',"5")
      if(this.title=='居家健康监控') this.$set(dataJson,'type',"6")
      this.axios.post("/prod-api/parents/report/gl",dataJson)
      .then(res=>{
        if(res.code==200){
          this.$toast.success("提交成功")
          this.$router.go(-1)
        }else{
          this.$toast(res.msg)
        }
      })
    },
    dateClick(type){
      this.currentType = type
      this.dateShow = true
      this.currentDate=new Date()
      if(type=='start' && this.startTime){
        this.currentDate = new Date(this.startTime)
      }else if(type=='end' && this.endTime){
        this.currentDate = new Date(this.endTime)
      }
    },
    dateConfirm(val){
      let year = val.getFullYear()//年
      let month = val.getMonth() + 1//月
      let day = val.getDate()//日
      // let hour = val.getHours()//时
      // let minute = val.getMinutes()//分
      if (month >= 1 && month <= 9) { month = `0${month}` }
      if (day >= 1 && day <= 9) { day = `0${day}` }
      // if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
      // if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
      // this.user_birthday = `${year}-${month}-${day} ${hour}:${minute}`
      if(this.currentType=="start"){
        this.startTime = `${year}-${month}-${day}`
      }else{
        this.endTime = `${year}-${month}-${day}`
      }
      this.dateShow = false
    },
  },
  created(){
    this.stuId = this.$route.query.stuId
    this.stuName = this.$route.query.stuName
    this.title = this.$route.query.title
  }
}
</script>

<style src="../assets/css/schoolmeshing.css" scoped></style>
<style scoped>

</style>