import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
import { Popup, DatetimePicker, Picker, Dialog, RadioGroup, Radio, Tab, Tabs, Toast, Checkbox, Cascader, Field, Button, Cell, CellGroup } from 'vant'
import 'vant/lib/index.css';

// (function getOpenid(){
//   var url = decodeURI(location.search);
//   var object = {};
//   if(url.indexOf("?") != -1){
//     var str = url.substr(1);
//     var strs = str.split("&");
//     for(var i = 0; i < strs.length; i ++){
//       object[strs[i].split("=")[0]]=strs[i].split("=")[1]
// 　　}
//     // console.log(object.openid)
// 　};
//   sessionStorage.setItem("openid","o9SLD5p4aFDF0t4cSH37IuLwrc-g");
//   if(object.openid){
//     sessionStorage.setItem("openid",object.openid);
//   }
// })()

Vue.config.productionTip = false

Vue.use(axios);
Vue.use(Popup).use(DatetimePicker).use(Picker).use(Dialog).use(RadioGroup).use(Radio).use(Tab).use(Tabs).use(Toast).use(Checkbox).use(Cascader).use(Field).use(Button).use(Cell).use(CellGroup)

// 分转元
Vue.filter(
  "fentoyuan",
  function(fen){
    var num = Number(fen);
    if(!num){//等于0
      return num+'.00';
    }else{//不等于0
      num = Math.round((num)*100)/10000;
      num = num.toFixed(2);
      num+='';//转成字符串
      var reg=num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;//千分符的正则
      // console.log(num.indexOf('.')>-1)
      return num.replace(reg, '$1,')//千分位格式化
    }
  }
);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
