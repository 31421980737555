<template>
  <div class="container">
    <div class="formbox">
      <div class="parentformitem">
        <label>请填写您与学生的关系</label>
        <input placeholder="例如母亲、父亲等" v-model="relation">
      </div>
      <div class="parentformitem">
        <label>请填写您的手机号码</label>
        <input placeholder="填写您的手机号" v-model="mobile">
      </div>
    </div>
    <!-- <p class="tips">您输入的手机号在系统中不存在，请您重新输入，或联系老师进行核实</p> -->
    <ul class="twobtn">
      <li class="cancel" @click="cancel">取消</li>
      <li @click="confirm">确定</li>
    </ul>
  </div>
</template>

<script>
export default {
  data(){
    return{
      stuId:"",
      relation:"",
      mobile:"",
    }
  },
  methods:{
    cancel(){
      this.$router.go(-1)
    },
    confirm(){
      if(this.relation.trim()==""){
        this.$toast("请填写您与学生的关系")
        return
      }
      if(this.mobile.trim()==""){
        this.$toast("请填写您的手机号码")
        return
      }
      this.axios.post("/prod-api/parents/students",{stuId:this.stuId,relation:this.relation,mobile:this.mobile})
      .then(res=>{
        if(res.code==200){
          this.$router.go(-2)
          this.$toast.success("绑定成功")
        }else{
          this.$toast(res.msg)
        }
      })
    }
  },
  created(){
    this.stuId = this.$route.query.stuId
  },
}
</script>

<style src="../assets/css/schoolmeshing.css" scoped></style>
<style scoped>

</style>