<template>
  <div class="container">
    <div class="formbox">
      <div class="formitem">
        <label class="formitemtitle">缴费类型：</label>
        <p class="formitemcon">{{details.goodsName}}</p>
      </div>
      <div class="formitem" v-for="(item,index) of skuList" :key="index">
        <label class="formitemtitle">{{item.label}}：</label>
        <input class="formitemcon" type="text" :placeholder="'请选择'+item.label" readonly @click="selectType(item.options)" v-model="selSku[item.label].text">
        <img src="@/assets/images/gengduo.png" class="choice">
      </div>
      <div>
        <div class="formitem" v-for="(item,index) of spuList" :key="index">
          <label class="formitemtitle">{{item.label}}：</label>
          <p class="formitemcon">{{item.value}}{{item.label=="天数"?"天":""}}</p>
        </div>
      </div>
      <div class="formitem">
        <label class="formitemtitle">说明：</label>
        <p class="formitemcon" style="text-align:left" v-html="details.details"></p>
      </div>
    </div>
    <div class="paybox">
      <p class="payprice">总计：<span>{{price | fentoyuan}}元</span></p>
      <span class="paybtn" @click="toPay">去支付</span>
    </div>
    <van-popup v-model="show" position="bottom">
      <van-picker
        title="标题"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data(){
    return{
      id:"",
      details:{
        id:"",
        goodsName:"",
        details:"",
      },
      skuList:[],
      spuList:[],
      show:false,
      columns:[],
      selSku:{},
      price:0,
      stuId:"",
      skuId:[],
    }
  },
  methods:{
    toPay(){
      for(var key in this.selSku){
        if(this.selSku[key].text==""){
          this.$toast(`请选择${key}`)
          return
        }
        this.skuId.push(this.selSku[key].id)
      }
      var dataJson = {
        paymentId:this.details.id,
        stuId:this.stuId,
        skuList:this.skuId
      }
      this.axios.post("/prod-api/parents/payment",dataJson)
      .then(res=>{
        if(res.code==200){
          sessionStorage.setItem("isPay","已缴")
          this.$router.go(-1)
        }
      })
    },
    onConfirm(value, index) {
      this.$set(this.selSku,value.label,value)
      this.show = false
      this.price=0
      for(var key in this.selSku){
        if(this.selSku[key].price){
          this.price+=Number(this.selSku[key].price)
        }
      }
    },
    onCancel() {
      this.show = false
    },
    selectType(options){
      for(var item of options){
        this.$set(item,"text",item.value)
      }
      this.columns = options
      this.show = true
    },
    getData(){
      this.axios.get(`/prod-api/parents/payment/${this.id}`)
      .then(res=>{
        this.details.id = res.data.id
        this.details.goodsName = res.data.goodsName
        this.details.details = res.data.details
        this.skuList = res.data.skuList
        this.spuList = res.data.spuList
        for(var item of this.skuList){
          this.$set(this.selSku,item.label,{})
          this.$set(this.selSku[item.label],'text',"")
        }
      })
    },
  },
  created(){
    this.id = this.$route.query.id
    this.stuId = this.$route.query.stuId
    this.getData()
  }
}
</script>

<style src="../assets/css/schoolmeshing.css" scoped></style>
<style scoped>

</style>