<template>
  <div class="container">
    <ul class="stulist" v-if="stuList.length>1">
      <li :class="{active:item.checked}" v-for="(item,index) of stuList" :key="index" @click="change(item)">{{item.name}}</li>
    </ul>
    <ul class="whethertab" :style="{'top':stuList.length>1?'1.02rem':'0'}">
      <li v-for="(item,index) of payTab" :key="index" :class="{active:item.checked}" @click="payChange(index)">{{item.text}}</li>
    </ul>
    <div class="listbox">
      <div class="empty" v-if="isEmpty">暂无缴费信息</div>
      <div v-else>
        <div class="listitem" v-for="(item,index) of payList" :key="index">
          <dl class="infocell"><dt>发送时间：</dt><dd>{{item.startTime}}</dd></dl>
          <dl class="infocell"><dt>发送人：</dt><dd>{{item.userName}}</dd></dl>
          <dl class="infocell"><dt>缴费类型：</dt><dd class="redtext">{{item.goodsName}}</dd></dl>
          <!-- <dl class="infocell"><dt>缴费金额：</dt><dd class="redtext">{{item.startTime}}</dd></dl> -->
          <dl class="infocell"><dt>截止时间：</dt><dd>{{item.endTime}}</dd></dl>
          <span class="listbtn" v-if="item.isPay=='未交'" @click="toPay(item.id)">去缴费</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NameTab from "../components/NameTab.vue"
export default {
  data(){
    return{
      stuList:[],
      stuId:"",
      isPay:"1",
      payTab:[{
        text:"待缴",
        value:"1",
        checked:true,
      },{
        text:"已缴",
        value:"0",
        checked:false,
      }],
      payList:[],
      isEmpty:false,
      
    }
  },
  components:{
    NameTab
  },
  methods:{
    toPay(id){
      this.$router.push({path:"/topay",query:{id,stuId:this.stuId}})
    },
    payChange(index){
      for(var item of this.payTab){
        item.checked = false
      }
      this.payTab[index].checked = true
      this.isPay = this.payTab[index].value
      this.getPayment()
    },
    getStu(){
      this.axios.get("/prod-api/parents/students/simplify")
      .then(res=>{
        this.stuList = res.data
        this.stuId = this.stuList[0].stuId
        for(var item of this.stuList){
          this.$set(item,"checked",false)
        }
        this.stuList[0].checked = true
        this.getPayment()
      })
    },
    change(key){
      for(var item of this.stuList){
        item.checked = false
      }
      key.checked = true
      this.stuId = key.stuId
      this.getPayment()
    },
    getPayment(){
      this.axios.get("/prod-api/parents/payment",{params:{stuId:this.stuId,isPay:this.isPay}})
      .then(res=>{
        this.payList = res.data
        if(this.payList.length==0){
          this.isEmpty = true
        }else{
          this.isEmpty = false
        }
        for(var item of this.payList){
          this.$set(item,"isOpenbtn",false)
          this.$set(item,"isOpen",false)
        }
      })
    },
  },
  created(){
    if(sessionStorage.getItem("isPay")){
      this.isPay = "0"
      for(var item of this.payTab){
        item.checked = false
      }
      this.payTab[1].checked = true
      sessionStorage.removeItem("isPay")
    }
    this.getStu()
  }
}
</script>

<style src="../assets/css/schoolmeshing.css" scoped></style>
<style scoped>

</style>