import axios from "axios";
import qs from "qs";
import router from './router'
import store from './store'
import { Toast } from 'vant'

const Axios=axios.create({
  baseURL:"https://school.culater.cn/",
  // baseURL:"http://192.168.10.56:8080/",
  withCredentials:true
})

Axios.interceptors.request.use(
  config=>{
    // console.log(config)
    // console.log("进入请求拦截器...");
    if(config.method==="post"){
      if(config.data.length>0){
      }else{
        // config.data=qs.parse(qs.stringify(config.data));
      }
    }
    if(sessionStorage.getItem('token')){
      // console.log(sessionStorage.getItem('token'))
      config.headers.Authorization=sessionStorage.getItem('token');
    }
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration:0,
    });
    return config;
  },
  error=>{
    console.log("===发送请求拦截器报错===")
    console.log(error);
    console.log("===end===");
    Promise.reject(error);
  }
);
Axios.interceptors.response.use(
  res=>{
    if(res.headers.authorization){
      sessionStorage.setItem("token",res.headers.authorization);
    }
    // console.log("触发响应拦截器...")
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration:1,
    });
    if(res.data.code==401){
      router.push("/login")
    }
    return res.data;
  },
  error=>{
    if(error.response.status==401){
      // store.dispatch('logoutUtil',{type:1});
      store.dispatch('logout',{type:1});
      return Promise.reject(401);
    }
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration:1,
    });
  }
)

export default {
  install: function(Vue, Option){
    Vue.prototype.axios=Axios;
  }
}