<template>
  <ul>
    <!-- <li class="active">张壹<span></span></li> -->
    <li v-for="(item,index) of stuList" :key="index" @click="change(item)">{{item.name}}</li>
  </ul>
</template>

<script>
export default {
  data(){
    return{
      stuList:[],
    }
  },
  methods:{
    getData(){
      this.axios.get("/prod-api/parents/students/simplify")
      .then(res=>{
        this.stuList = res.data
        for(var item of this.stuList){
          item.checked = false
        }
        this.$emit("childData",this.stuList)
      })
    },
    change(key){
      for(var item of this.stuList){
        item.checked = false
      }
      key.checked = true
    }
  },
  created(){
    this.getData()
  }
}
</script>

<style scoped>
ul{
  display: flex;
  background-color: #fff;
  border-bottom: 0.02rem solid #e6e6e6;
  align-items: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
}
ul li{
  flex: 1;
  color: #999;
  font-size: 0.3rem;
  height: 1rem;
}
ul li.active{
  color: #4e80e2;
  font-weight: bold;
}
ul li span{
  display: inline-block;
  width: 0.12rem;
  height: 0.12rem;
  background-color: #ff5068;
  border-radius: 50%;
  vertical-align: text-top;
  margin-left: 0.1rem;
}
</style>