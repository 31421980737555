<template>
  <div class="container">
    <ul class="stulist" v-if="stuList.length>1">
      <!-- <li class="active">张壹<span></span></li> -->
      <li :class="{active:item.checked}" v-for="(item,index) of stuList" :key="index" @click="change(item)">{{item.name}}</li>
    </ul>
    <ul class="whethertab" :style="{'top':stuList.length>1?'1.02rem':'0'}">
      <li v-for="(item,index) of readTab" :key="index" :class="{active:item.checked}" @click="readChange(index)">{{item.text}}</li>
    </ul>
    <div class="listbox">
      <div class="empty" v-if="isEmpty">暂无通知</div>
      <div class="listitem" v-for="(item,index) of noticeList" :key="index" v-else>
        <span class="unread" v-if="!item.url" :class="{read:item.isRead=='已读'}" @click="readClick(item)">确认已读</span>
        <dl class="infocell"><dt>发送时间：</dt><dd>{{item.createTime}}</dd></dl>
        <dl class="infocell"><dt>发送人：</dt><dd>{{item.userName}}</dd></dl>
        <dl class="infocell" v-if="item.url"><dt>会议链接：</dt><dd><span @click="meetingLinks(item)">{{item.url}}</span></dd></dl>
        <dl class="infocell">
          <dt>通知内容：</dt>
          <dd :style="{overflow:item.isOpen?'':'hidden','max-height':item.isOpen?'':'0.74rem'}">
            <p class="overflowhidden" ref="overflowhidden">{{item.content}}</p>
            <p class="ellipsis" ref="ellipsis" :style="{'display':item.isOpen?'block':'-webkit-box'}">{{item.content}}</p>
          </dd>
        </dl>
        <span class="arrow" :class="[item.isOpen?'collapse':'expand']" v-if="item.isOpenbtn" @click="open(index)">{{item.isOpen?'收起':'展开'}}</span>
        <span class="listbtn" @click="toReport(item.type)" v-if="item.type!=35&&item.type!=36&&item.type!=37">去申报</span>
      </div>
    </div>
    <ul class="noticetabbar">
      <li class="news" :class="{active:noticeType=='消息'}" @click="noticeChange('消息')">消息通知</li>
      <li class="meeting" :class="{active:noticeType=='会议'}" @click="noticeChange('会议')">会议通知</li>
    </ul>
  </div>
</template>

<script>
import NameTab from "../components/NameTab.vue"
export default {
  data(){
    return{
      stuList:[],
      stuId:"",
      isRead:"0",
      readTab:[{
        text:"未读",
        value:"0",
        checked:true,
      },{
        text:"已读",
        value:"1",
        checked:false,
      }],
      noticeType:"消息",
      noticeList:[],
      isEmpty:false,
    }
  },
  components:{
    NameTab
  },
  methods:{
    meetingLinks(item){
      this.readClick(item)
      window.location.href = item.url
    },
    toReport(type){
      console.log(type)
      if(type==30){
        this.$router.push({path:"/reportym",query:{stuId:this.stuId,stuName:this.stuName,title:"疫苗接种"}})
      }else if(type==29){
        this.$router.push({path:"/reporths",query:{stuId:this.stuId,stuName:this.stuName,title:"核酸检测"}})
      }else if(type==31){
        this.$router.push({path:"/reporttw",query:{stuId:this.stuId,stuName:this.stuName,title:"体温上报"}})
      }else if(type==32){
        this.$router.push({path:"/reportgl",query:{stuId:this.stuId,stuName:this.stuName,title:"居家隔离"}})
      }else if(type==33){
        this.$router.push({path:"/reportgl",query:{stuId:this.stuId,stuName:this.stuName,title:"集中隔离"}})
      }else if(type==34){
        this.$router.push({path:"/reportgl",query:{stuId:this.stuId,stuName:this.stuName,title:"居家健康监控"}})
      }
    },
    readClick(item){
      if(item.isRead=='已读') return
      var params = new URLSearchParams();
      params.append('id', item.id);
      this.axios.post("/prod-api/parents/news",params)
      .then(res=>{
        if(res.code==200){
          item.isRead="已读"
        }else{
          this.$toast(res.msg)
        }
      })
    },
    open(index){
      this.noticeList[index].isOpen = this.noticeList[index].isOpen?false:true
    },
    noticeChange(type){
      this.noticeType = type
      if(this.noticeType=='消息'){
        this.getNews()
      }else if(this.noticeType=='会议'){
        this.getMeeting()
      }
    },
    readChange(index){
      for(var item of this.readTab){
        item.checked = false
      }
      this.readTab[index].checked = true
      this.isRead = this.readTab[index].value
      if(this.noticeType=='消息'){
        this.getNews()
      }else if(this.noticeType=='会议'){
        this.getMeeting()
      }
    },
    getStu(){
      this.axios.get("/prod-api/parents/students/simplify")
      .then(res=>{
        this.stuList = res.data
        this.stuId = this.stuList[0].stuId
        this.stuName = this.stuList[0].name
        for(var item of this.stuList){
          this.$set(item,"checked",false)
        }
        this.stuList[0].checked = true
        this.getNews()
      })
    },
    change(key){
      for(var item of this.stuList){
        item.checked = false
      }
      key.checked = true
      this.stuId = key.stuId
      this.stuName= key.name
      if(this.noticeType=='消息'){
        this.getNews()
      }else if(this.noticeType=='会议'){
        this.getMeeting()
      }
    },
    getNews(){
      this.axios.get("/prod-api/parents/news",{params:{stuId:this.stuId,isRead:this.isRead}})
      .then(res=>{
        this.noticeList = res.data
        if(this.noticeList.length==0){
          this.isEmpty = true
        }else{
          this.isEmpty = false
        }
        for(var item of this.noticeList){
          this.$set(item,"isOpenbtn",false)
          this.$set(item,"isOpen",false)
        }
        this.$nextTick(()=>{
          let overflowRef= this.$refs.overflowhidden;
          let ellipsisRef= this.$refs.ellipsis;
          for(var index in this.noticeList){
            if(overflowRef[index].offsetHeight>ellipsisRef[index].offsetHeight){
              this.noticeList[index].isOpenbtn = true
            }
          }
        })
      })
    },
    getMeeting(){
      this.axios.get("/prod-api/parents/news/meeting",{params:{stuId:this.stuId,isRead:this.isRead}})
      .then(res=>{
        this.noticeList = res.data
        if(this.noticeList.length==0){
          this.isEmpty = true
        }else{
          this.isEmpty = false
        }
        for(var item of this.noticeList){
          this.$set(item,"isOpenbtn",false)
          this.$set(item,"isOpen",false)
        }
        this.$nextTick(()=>{
          let overflowRef= this.$refs.overflowhidden;
          let ellipsisRef= this.$refs.ellipsis;
          for(var index in this.noticeList){
            if(overflowRef[index].offsetHeight>ellipsisRef[index].offsetHeight){
              this.noticeList[index].isOpenbtn = true
            }
          }
        })
      })
    },
  },
  created(){
    this.getStu()
  }
}
</script>

<style src="../assets/css/schoolmeshing.css" scoped></style>