<template>
  
</template>
<script>
export default {
  data(){
    return{

    }
  },
  methods:{
    getParams(){
      var url = decodeURI(location.search);
      var object = {};
      if(url.indexOf("?") != -1){
        var str = url.substr(1);
        var strs = str.split("&");
        for(var i = 0; i < strs.length; i ++){
          object[strs[i].split("=")[0]]=strs[i].split("=")[1]
    　　}
        return object;
    　};
    }
  },
  created(){
    var params = new URLSearchParams();
    var rq = this.getParams();
    params.append('openid', sessionStorage.getItem("openid"));
    this.axios.post("/prod-api/parents/wxLogin", params)
    .then(res=>{
      sessionStorage.setItem("token",res.token);
      sessionStorage.setItem("url",rq.url);
      if(res.flag==0){
        this.$router.push("/authorize")
      }else{
        this.$router.push(sessionStorage.getItem("url"))
      }
    })
  }
}
</script>