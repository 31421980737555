<template>
  <div class="container">
    <div class="mineemptybox" v-if="isEmpty">
      <p>您还未绑定任何学生信息，点击【添加】，<br>绑定学生信息，快速接收学校通知！</p>
      <div class="addbtn" @click="$router.push('/addstudents')"><img src="@/assets/images/add.png">添加</div>
    </div>
    <div v-else>
      <h2 class="reporttitle">已绑定学生信息</h2>
      <div class="listbox">
        <div class="listitem threewords" v-for="(item,index) of dataList" :key="index">
          <dl class="infocell"><dt>学生：</dt><dd>{{item.name}}</dd></dl>
          <dl class="infocell"><dt>学校：</dt><dd>{{item.school}}</dd></dl>
          <dl class="infocell"><dt>班级：</dt><dd>{{item.classes}}</dd></dl>
          <dl class="infocell"><dt>班主任：</dt><dd>{{item.teacher}}</dd></dl>
          <span class="listbtn" @click="edit(item)">修改家长手机号</span>
        </div>
        <div class="addbtn" @click="$router.push('/addstudents')"><img src="@/assets/images/add.png">添加</div>
      </div>
    </div>
    <van-dialog v-model="telShow" show-cancel-button @confirm="telConfirm" @cancel="telCancel">
      <div class="parentform">
        <div class="parentformitem">
          <label>请填写您的手机号码</label>
          <input placeholder="填写您的手机号" v-model="mobile">
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Dialog } from 'vant'
export default {
  data(){
    return{
      telShow:false,
      isEmpty:false,
      dataList:[],
      mobile:"",
      currentStu:"",
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  methods:{
    getData(){
      this.axios.get("/prod-api/parents/students")
      .then(res=>{
        if(res.data.length==0){
          this.isEmpty=true
        }
        this.dataList = res.data
      })
    },
    edit(item){
      this.telShow=true
      this.mobile = ""
      this.currentStu = item
    },
    telConfirm(){
      if(this.mobile.trim()==""){
        this.$toast("请填写您的手机号")
        return
      }
      this.axios.put("/prod-api/parents/students",{id:this.currentStu.id,relation:this.currentStu.relation,mobile:this.mobile})
      .then(res=>{
        if(res.code==200){
          this.telShow = false
          this.$toast.success("修改成功")
        }else{
          this.$toast(res.msg)
        }
      })
    },
    telCancel(){
      this.mobile = ""
    }
  },
  created(){
    this.getData()
  }
}
</script>

<style src="../assets/css/schoolmeshing.css" scoped></style>
<style scoped>

</style>